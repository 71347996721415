import React from 'react';
import Layout from '../../../components/Layout';
import { Container, Row, Column } from '../../../components/Grid';
import Section from '../../../components/Section';
import { Link } from 'gatsby';
import Image from '../../../components/Image';

const KaienYang = () => (
  <Layout title="Alumni Spotlight - Kaien Yang" className="alumni-center bios">
    {/* Alumni Profile */}
    <Section className="alumni">
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../overview">Overview</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../alumni-spotlight">Alumni Spotlight</Link>
          </li>
          <li className="breadcrumb active">Kaien Yang</li>
        </ul>
        <Row>
          <Column size={7} offset={1} className="alumni__profile-left pr-2">
            <h2 className="alumni__profile-greeting">Meet Alumni:</h2>
            <h1 className="alumni__profile-title">Kaien Yang</h1>
            <h3 className="alumni__profile-type">
              2016 Young Scientist Challenge Finalist
            </h3>
            <h4 className="alumni__profile-project">
              Kaien is a sophomore at Standford University majoring in Computer
              Science with an AI track. He works with robots and machine
              learning, and plays for Stanford’s club tennis team.
            </h4>
            <h2 className="alumni__profile-question">
              What have you been up to since participating in the 3M Young
              Scientist Challenge?
            </h2>
            <p>
              It feels just like yesterday when I was touring the 3M Innovation
              Center and cheering on my fellow finalists, but here we are six
              years later! College is a magical experience full of discovery and
              boundless excitement. A highlight of my time has been joining the
              Stanford IRIS Lab, where I apply machine learning to robotics and
              develop algorithms to help robots learn from data.
            </p>
            <h2 className="alumni__profile-question">
              Are you involved in any school teams or clubs, if so which ones?
            </h2>
            <p>
              I'm on the Stanford club tennis team — bonding with my teammates
              at practices and tournaments is always a highlight of my week.
            </p>
            <h2 className="alumni__profile-question">
              What are your plans for the future?
            </h2>
            <p>
              I want to innovate at the nexus of AI and autonomy, whether it be
              conducting research in a lab, developing products at a dynamic
              startup, driving high-impact technologies at a large corporation,
              or all of the above!
            </p>
            <h2 className="alumni__profile-question">
              What advice do you have for a student who is considering entering
              the 3M Young Scientist Challenge? How would you say the 3M Young
              Scientist Challenge has impacted your life?
            </h2>
            <p>
              By all means, DO IT! The 3M Young Scientist Challenge has given me
              a broad network of peers and friends, greater confidence in my
              scientific abilities, and the memories of a lifetime.
            </p>
            <h2 className="alumni__profile-question">
              Have you stayed in contact with your 3M mentor? Anything you’d
              like to share about your experience working with a 3M mentor?
            </h2>
            <p>
              My 3M mentor <Link to="#">Dr. Mahfuza Ali</Link> has made an
              immense impact on me. She's a world-class scientist, Carlton
              Society inductee, and a tremendous mentor to many students. Ever
              since our first call together in summer 2016, Dr. Ali has provided
              ongoing guidance to me. I'm so glad we've stayed in touch after
              the 3M Young Scientist Challenge. Anyone who has her as a mentor
              is beyond lucky.
            </p>
          </Column>
          <Column size={4}>
            <div className="global__profile-box box-shadow">
              <div className="global__profile-image">
                <Image filename="Kaien-Yang-Alumni-Quote.jpg" />
              </div>
              <div className="global__profile-content">
                <h4 className="quote">
                  “The 3M Young Scientist Challenge has given me a broad network
                  of peers and friends, greater confidence in my scientific
                  abilities, and the memories of a lifetime.”
                </h4>
                <h3>Kaien Yang</h3>
                <p>2016 Finalist</p>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Related Alumni Profiles */}
    <Section className="related-profiles">
      <Container>
        <Row>
          <Column fullwidth>
            <h2>Related Alumni</h2>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Nicholas-LaJoie-Alumni-Card.jpg" />
              </div>
              <div className="profile-card__content">
                <span>2009 Finalist</span>
                <h3>Nicholas LaJoie</h3>
                <p>
                  Nicholas studied Computer Engineering in college and is
                  currently pursuing a career in technology. He plays the drums
                  and has worked in filmmaking.
                </p>
                <Link to="../nicholas-lajoie" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Faraz-Tamboli-Alumni-Card.jpg" />
              </div>
              <div className="profile-card__content">
                <span>2019 Finalist</span>
                <h3>Faraz Tamboli</h3>
                <p>
                  Since participating in the 2019 Challenge, Faraz has continued
                  to improve and enhance his entry project, Talk Motion. Faraz
                  is currently in 9th grade and enjoys reading fantasy stories,
                  making his own music and participating in Mock Trial Club.
                </p>
                <Link to="../faraz-tamboli" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Anna-Du-Alumni-Card.jpg" />
              </div>
              <div className="profile-card__content">
                <span>2018 Finalist</span>
                <h3>Anna Du</h3>
                <p>
                  Anna participated in the 2018 3M Young Scientist Challenge and
                  continues to work on her innovation today. Now in 11
                  <sup>th</sup> grade, Anna is extremely passionate about the
                  environment and loves reading and astronomy.
                </p>
                <Link to="../anna-du" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default KaienYang;
